
import { defineComponent, onMounted, ref } from 'vue';

import api$ from '@/services/tau-apis';
import {
  EventsubSubscriptionResponse,
  EventsubSubscription,
} from '@/models/twitch-helix-endpoint';

export default defineComponent({
  setup() {
    const webhooks = ref<EventsubSubscription[]>([]);
    const publicUrl = ref('');

    onMounted(async () => {
      const publicUrlData = await api$.tau.get('public_url');
      publicUrl.value = publicUrlData.public_url.split('/')[2];
      console.log(publicUrl.value);
      const data = await api$.helix.get<EventsubSubscriptionResponse>(
        'eventsub/subscriptions',
      );
      console.log(data.data);
      webhooks.value = data.data;
    });

    const isLocal = function (url: string) {
      const baseUrl = url.split('/')[2];
      return baseUrl === publicUrl.value;
    };

    const resetWebhooks = async function (resetType: string) {
      const payload = {
        type: resetType,
      };
      const resp = await api$.tau.post('reset-webhooks', payload);
    };

    return {
      webhooks,
      resetWebhooks,
      isLocal,
    };
  },
});
